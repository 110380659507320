import { tagAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'
// https://chakra-ui.com/docs/components/tag/theming
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys)

const subtle = definePartsStyle({
  container: {},
})

const alert = definePartsStyle({
  container: {
    bg: 'alert.caution',
    color: 'white',
    verticalAlign: '-webkit-baseline-middle',
  },
})
const sm = defineStyle({
  px: 1,
  py: '2px',
  fontSize: 12,
})

const ml = defineStyle({
  px: 2,
  py: 1,
})

const sizes = {
  sm: definePartsStyle({ container: sm, label: sm }),
  ml: definePartsStyle({ container: sm, label: ml }),
}

export const tagTheme = defineMultiStyleConfig({
  sizes,
  defaultProps: {
    variant: 'subtle',
  },
  variants: {
    subtle,
    alert,
  },
})
