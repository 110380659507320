import { extendTheme } from '@chakra-ui/react'
import { MultiSelectTheme } from 'chakra-multiselect'

import { buttonTheme } from './lib/chakraThema/button'
import { tagTheme } from './lib/chakraThema/tag'

const theme = {
  styles: {
    global: {},
  },
  colors: {
    alert: {
      caution: '#E53E3E',
    },
    text: {
      primary: '#171923',
    },
  },
  components: {
    Tag: tagTheme,
    MultiSelect: MultiSelectTheme,
    Button: buttonTheme,
  },
}

export const chakraTheme = extendTheme({
  ...theme,
})
