import React from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { useNetworkConnectivity } from '@remix-pwa/client'

import { chakraTheme } from '@/chakraTheme'
import { AppStateContainer } from '@/context/AppStateContainer'
import type { EntryExit } from '@/types/entryExit'
import type { SiteComment } from '@/types/siteComment'
import { getItem, localStorageKeys } from '@/util/localStorageControle'
import type { UserComment } from '@/types/userComment'

const App: React.FC<{ children: React.ReactNode }> = ({ children }) => {

  const { setIsOnline, setSiteComments, setEntryExits, setUserComments } =
    AppStateContainer.useContainer()

  const setLocalStorage = () => {
    const { siteComments, entryExits, userComments } = localStorageKeys

    const sitecomments = getItem(siteComments) as SiteComment[] | null
    const usercomments = getItem(userComments) as UserComment[] | null
    const entryexits = getItem(entryExits) as EntryExit[] | null
    
    if (sitecomments) {
      setSiteComments(sitecomments)
    }
    if (usercomments) {
      setUserComments(usercomments)
    }

    if (entryexits) {
      setEntryExits(entryexits)
    }
  }

  useNetworkConnectivity({
    onOnline: () => {
      setIsOnline(true)
      setLocalStorage()
    },
    onOffline: () => setIsOnline(false),
  })

  React.useEffect(() => {
    if (typeof navigator === 'undefined') return
    setIsOnline(navigator.onLine)
    setLocalStorage()
  }, [])
  return (
    <ChakraProvider resetCSS theme={chakraTheme}>
      {children}
    </ChakraProvider>
  )
}

export const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <AppStateContainer.Provider>
      <App>{children}</App>
    </AppStateContainer.Provider>
  )
}
