import { defineStyleConfig } from '@chakra-ui/react'

const buttonDefaultStyle = {}

// https://chakra-ui.com/docs/styled-system/component-style
export const buttonTheme = defineStyleConfig({
  variants: {
    circle: () => {
      return {
        ...buttonDefaultStyle,
        color: 'white',
        borderRadius: 'full',
        boxShadow: 'lg',
        height: '100px',
        width: '100px',
        opacity: '0.9',
        _hover: {
          opacity: '1',
        },
      }
    },
  },
})
